import {EventStatus} from '@wix/events-types'
import {AnyAction} from 'redux'
import {loadCalendarEvents, loadEventsPage} from '../actions/events'
import {LOAD_MEMBERS_FOR_EVENTS} from '../actions/members'
import {Events} from '../types/state'

const defaultState: Events = {
  events: [],
  moreLoading: false,
  hasMore: false,
  filterType: null,
}

export const events = (state = defaultState, action: AnyAction): Events => {
  switch (action.type) {
    case LOAD_MEMBERS_FOR_EVENTS.SUCCESS:
      const guestLists = action.payload as GuestLists

      return {
        ...state,
        events: [
          ...state.events.map(event => ({
            ...event,
            ...(guestLists[event.id] || {}),
          })),
        ],
      }
    case loadEventsPage.pending.toString(): {
      if (loadEventsPage.pending.match(action)) {
        const offset = action.meta.arg
        return {
          ...state,
          moreLoading: offset !== 0,
        }
      }

      return state
    }
    case loadEventsPage.fulfilled.toString(): {
      if (loadEventsPage.fulfilled.match(action)) {
        const offset = action.meta.arg
        const reset = offset === 0

        return {
          ...state,
          events: reset ? action.payload.events : [...state.events, ...action.payload.events],
          hasMore: action.payload.hasMore,
          moreLoading: false,
        }
      }

      return state
    }
    case loadCalendarEvents.fulfilled.toString(): {
      if (loadCalendarEvents.fulfilled.match(action) && action.payload) {
        const reset = action.meta.arg.fullReset
        const loadedEvents = reset ? [] : state.events
        const existingEventIds = loadedEvents.map(event => event.id)
        const newEvents = action.payload.events.filter(event => !existingEventIds.includes(event.id))
        return {
          ...state,
          events: [...loadedEvents, ...newEvents],
          hasMore: false,
          moreLoading: false,
        }
      }

      return state
    }
    case loadEventsPage.rejected.toString():
      if (loadEventsPage.rejected.match(action)) {
        return {
          ...state,
          moreLoading: false,
        }
      }

      return state
    default:
      return state
  }
}

export const sortEvents = (eventList: wix.events.Event[]) => {
  const upcomingEvents = getEventsByStatusSortedByDate(eventList, [EventStatus.SCHEDULED, EventStatus.STARTED])
  const pastEvents = getEventsByStatusSortedByDate(eventList, [EventStatus.ENDED])
  const canceledEvents = getEventsByStatusSortedByDate(eventList, [EventStatus.CANCELED])

  return [].concat(upcomingEvents, pastEvents, canceledEvents)
}

const getEventsByStatusSortedByDate = (
  eventList: wix.events.Event[],
  statuses: wix.events.EventStatus[],
): wix.events.Event[] => eventList.filter((event: wix.events.Event) => statuses.indexOf(event.status) > -1)
